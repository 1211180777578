// import { Menu } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { get_card_imports } from "../../actions/cards/cards";
// import PageHeader from "../../PageHeader";
import Table from "../Common/Table/Table";
// import moment from "moment";
import AddFile from "../../assets/icons/add-file.png";
import { saveAs } from "file-saver";
import endpoint from "../../config/Axios";
import { apis } from "../../config/APIs";

const columns = [
  {
    title: "S.NO",
    dataIndex: "id",
    key: "id",
    render: (text, data, i) => {
      return <div className={`status-line`}>{i + 1}</div>;
    },
  },
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    className: "user-col",
  },
  {
    title: "Card Status",
    dataIndex: "status",
    className: "user-col",
    key: "status",
  },


  {
    title: "Actions",
    dataIndex: "id",
    width: 100,
    key: "id",
    render: (text) => (
      <span className="cursor" onClick={() => {
        endpoint.get(apis.staffCardDownloadFile, {
          params: {
            id: text
          }
        }).then(res => {
          let csv = atob(res?.data?.response?.card_file)
          var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
          saveAs(blob, "MIS.csv");
        })
      }}>
        <img src={AddFile} alt={"success_doc"} width={18} />
      </span>
    ),
  },
];

const CardImportsList = () => {
  const [filters, setFilters] = React.useState({});
  // const client_data = useSelector(
  //   (state) =>
  //     state?.client?.getClientList?.data?.response?.customize_data?.name
  // );
  const cards = useSelector(
    (state) => state?.cards?.card_imports?.data?.response?.card_imports
  );
  const cards_total = useSelector(
    (state) => state?.cards?.card_imports?.data?.total_records
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(get_card_imports({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleSearch = (value) => {
    dispatch(
      get_card_imports({ page: filters.page ? filters.page : 1, search: value })
    );
    setFilters({
      ...filters,
      search: value,
    });
  };

  return (
    <Table
      columns={columns}
      data={cards}
      // topFilters={topFilters}
      handleSearch={handleSearch}

      pagination={{
        total: cards_total || 0,
        onChange: (page) => {
          dispatch(get_card_imports({ ...filters, page }));
        },
      }}
    />
  );
};

export default withRouter(CardImportsList);
