import React from "react";
// import Input from "../FormComponents/InputComponent";
import { Button, Checkbox, Form, Input, message, Typography } from "antd";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import * as yup from "yup";
import Logo from "../../assets/images/paycraft.svg";
import { Login, login } from "./../../actions/auth";
import { hasAccess, RouteConstants } from "../../utils";
// import { RouteConstants } from "../../utils";
const { Text, Title } = Typography;

const ValidationSchema = yup.object().shape({
  email: yup.string().email().required("Please provide valid email"),
  password: yup.string().required("Password is required"),
});

const LoginForm = (props) => {
  const [accountType] = React.useState("simulator");
  const dispatch = useDispatch();
  const loginResponse = useSelector((state) => state.auth.login);
  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: (values) => {
      dispatch(login(values));
    },
  });

  React.useEffect(() => {
    if (loginResponse.success) {
      if (loginResponse?.data?.response && loginResponse?.data?.response?.auth_token) {
        localStorage.setItem("token", loginResponse?.data?.response?.auth_token);
        localStorage.setItem("permissions", JSON.stringify(loginResponse?.data?.response?.permission));
        dispatch({
          type: Login.RESET
        })
        message.success("Logged In Successfully");
        if (hasAccess("clients", "write") || hasAccess("clients", "read"))
          history.push(RouteConstants.CLIENTS)
        else if (hasAccess("permission_users", "write") || hasAccess("permission_users", "read"))
          history.push(RouteConstants.USERS)
        else if (hasAccess("cards", "write") || hasAccess("cards", "read"))
          history.push(RouteConstants.CARDS)
        else if (hasAccess("card_imports", "write") || hasAccess("card_imports", "read"))
          history.push(RouteConstants.CARD_IMPORTS)
        else if (hasAccess("card_vendors", "write") || hasAccess("card_vendors", "read"))
          history.push(RouteConstants.CARD_VENDORS)
        else if (hasAccess("staff_users", "write") || hasAccess("staff_users", "read"))
          history.push(RouteConstants.STAFF_USERS)
        else if (hasAccess("leads", "write") || hasAccess("leads", "read"))
          history.push(RouteConstants.LEADS)
      }
    } else if (loginResponse?.success === false) {
      message.error(<span className="messageText">{loginResponse?.data?.message ?? "Server is down, Please try after sometime."}</span>);
      dispatch({
        type: Login.RESET
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginResponse]);

  // const updateAccountType = (type) => {
  //   setAccountType(type);
  // };

  return (
    <div className="login-form">
      <img src={Logo} alt={"logo"} className="logo" />
      <div className="head-sec">
        <Title level={3}>Welcome back</Title>
        <Text className="text-white">
          {accountType === "simulator"
            ? "Sign in to pay craft Admin Internal Portal"
            : "Sign in to pay craft Simulator Account"}
        </Text>
      </div>
      <Form
        onFinish={formik.handleSubmit}
        layout="vertical"
        className="loginFormDiv"
      >
        <Form.Item
          label={
            accountType === "simulator"
              ? "Email Address"
              : "Client Series Or PCUID"
          }
          name="email"
        >
          <Input
            placeholder="Enter Email"
            name="email"
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email && (
            <div className="error">{formik.errors.email}</div>
          )}
        </Form.Item>

        <Form.Item label="Password">
          <Input.Password
            name="password"
            placeholder="Enter password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />
          {formik.touched.password && formik.errors.password && (
            <div className="error">{formik.errors.password}</div>
          )}
        </Form.Item>
        {accountType === "simulator" ? (
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
        ) : (
          <br />
        )}

        <Form.Item className="loginButtonsDiv">
          <Button
            htmlType="submit"
            className="loginButton"
            disabled={loginResponse.loading}
          >
            Login
          </Button>
          {/* {accountType === "simulator" ? (
            <Button
              htmlType="submit"
              className="loginSwitchSimulatorButton"
              onClick={() => updateAccountType("admin")}
            >
              <UserOutlined style={{ color: "white" }} />
              Switch to Simulator
            </Button>
          ) : (
            <Button
              htmlType="submit"
              className="loginSwitchSimulatorButton"
              onClick={() => updateAccountType("simulator")}
            >
              <UserOutlined style={{ color: "white" }} />
              Switch to Admin
            </Button>
          )} */}
          {/* <Link to={RouteConstants.RESETPASSWORD}>
            <span className="text-dim ml2 cursor">Forgot password?</span>
          </Link> */}
        </Form.Item>
      </Form>
      {/* <div className="mt4">
        <span className="text-white footer-note" style={{ opacity: 0.9 }}>
          Unable to Login? Click here to <b> Contact Support</b>
        </span>
      </div> */}
    </div>
  );
};

export default withRouter(LoginForm);
