import { Col, Row } from "antd";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import "./App.css";
import Login from "./components/Auth/Login";
import CardImports from "./components/Cards/CardImports";
import CardsDashboard from "./components/Cards/CardsDashboard";
import Clients from "./components/Clients/routes/index";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Leads from "./components/Leads/Leads";
import LeftNavigation from "./components/LeftNavigation/LeftNavigation";
import Staff from "./components/Settings/UsersControls/StaffUser/Staff";
import Users from "./components/Users/routes/index";
import history from "./history";
import CardVendors from "./components/Cards/CardVendors/CardVendors";

export const HeaderFooterWrap = (props) => {
  return (
    <div className="ml1 mr1">
      <div className="g-header mt1 mb1">
        <Header />
      </div>
      <Row className="main-content">
        <Col
          style={{
            width: "14%",
          }}
        >
          <LeftNavigation />
        </Col>
        <Col
          className="pt1 pl2 pr2"
          style={{
            width: "86%",
          }}
        >
          {props.children}
        </Col>
      </Row>
      <Footer />
      {/* <div className="grid main-area">
        <div className="g-header pt1">
          <Header />
        </div>
        <div className="g-nav">
          <LeftNavigation />
        </div>
        <div className="g-content">
          {props.children}
        </div>
        <div className="g-footer">
          <Footer />
        </div>
      </div> */}
    </div>
  );
};
const RedirectLogin = () => {
  return <Redirect to={"/admin/login"} />
}

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <div>
            <Route exact path={"/"} component={RedirectLogin} />
            <Route path="/admin/login" component={Login} exact />
            <Route path="/admin/resetpassword/" component={Login} />
            <Route
              path="/admin/clients"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap>
                    <Clients path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/users"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap>
                    <Users path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/staff-users"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap>
                    <Staff path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
             <Route
              path="/admin/card-vendors"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap>
                    <CardVendors path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/leads"
              render={({ match }) => {
                return (
                  <HeaderFooterWrap>
                    <Leads path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/cards/imports"
              exact
              render={({ match }) => {
                return (
                  <HeaderFooterWrap>
                    <CardImports path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
            <Route
              path="/admin/cards"
              exact
              render={({ match }) => {
                return (
                  <HeaderFooterWrap>
                    <CardsDashboard path={match.path} />
                  </HeaderFooterWrap>
                );
              }}
            ></Route>
          </div>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
