import React from "react";
import PageHeader from "../PageHeader";
import CardImportsList from "./CardImportsList";

const CardImports = () => {
  return (
    <>
      <div className="heading-div">
        <PageHeader
          title={`Card Imports`}
        />
      </div>
      <CardImportsList />
    </>
  );
};

export default CardImports;
