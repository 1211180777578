import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu, Space } from "antd";
import React, { useState } from "react";
import "./table.css";
const MAX_ITEMS = 3;

const MoreLessExample = (props) => {
  const [state, setState] = useState({
    isOpen: false,
    items: [],
    selectedItem: {},
  });

  const toggle = () => {
    setState({ isOpen: !state.isOpen });
  };

  const menu = (ele) => (
    <Menu>
      {ele.searchOption ? <input placeholder="Search..." className="m1" onChange={(e) => {
        ele.actionSearch && ele.actionSearch(e.target.value);
      }} /> : ""}
      {/* <Menu.Item
        onClick={() => {
          ele.action && ele.action("");
          setState({
            selectedItem: {
              ...state.selectedItem,
              [ele.label]: "",
            },
          });
        }}
      >
        Clear
      </Menu.Item> */}
      {ele &&
        ele.options &&
        ele.options.map((option, index) => (
          <Menu.Item
            key={index}
            eventKey={index}
            onClick={() => {
              ele.action && ele.action(option);
              setState({
                selectedItem: {
                  ...state.selectedItem,
                  [ele.label]: option,
                },
              });
            }}
            style={{
              textTransform: "capitalize"
            }}
          >
            {option}
          </Menu.Item>
        ))}
    </Menu>
  );

  return (
    <div className="d-flex flt-btns ml1">
      <div>
        {props.topFilters?.map((item, id) =>
          item.type === "dropdown" ? (
            <Space wrap key={id}>
              <Dropdown overlay={() => menu(item)}>
                <Button>
                  {state.selectedItem?.[item?.label]
                    ? state.selectedItem?.[item?.label]
                    : item.label}{" "}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </Space>
          ) : (
            ""
          )
        )}
        {MAX_ITEMS < props.topFilters?.length && (
          <span onClick={toggle} className="moreLessText">
            {state.isOpen ? "Less..." : "More..."}
          </span>
        )}
      </div>
    </div>
  );

}

export default MoreLessExample;
