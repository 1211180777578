
import {
  Button, Checkbox, Input, message
} from 'antd';
import { useFormik } from 'formik';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AddStaffUser, addStaffUser, staffUserList } from '../../../../actions/employees';
import { apis } from '../../../../config/APIs';
import endpoint from '../../../../config/Axios';
import * as yup from 'yup';
import PasswordCheck from '../../../Common/PasswordCheck';
const AddStaff = ({ onCancel, selectedData }) => {
  const dispatch = useDispatch();
  const add_staff = useSelector(state => state?.staff_users?.add?.data)
  const add_staff_loading = useSelector(state => state?.staff_users?.add?.loading)
  const formik = useFormik({
    initialValues: {
      email: "",
      role: "Admin",
      password: "",
      name: "",
      phone_no: "",
      designation: "",
      permission_ids: []
    },
    validationSchema: yup.object().shape({
      email: yup.string().email("Email must be valid.").required("Email is required"),
      password: yup.string().when('id', {
        is: selectedData?.id,
        then: yup.string(),
        otherwise: yup.string().required("Password is required")
      }),
      permission_ids: yup.array().of(yup.string()).min(1).required("Permissions are required")
    }),
    onSubmit: (vals) => {
      dispatch(addStaffUser(vals))
    }
  })

  const [permissions, setPermission] = useState({})
  const [passwordCheck, setPasswordCheck] = useState(false);
  useEffect(() => {
    endpoint.get(apis.staffPermissions).then(res => {
      let keys = res.data?.response?.permission || [];
      let permissionObj = {}
      keys?.forEach(ele => {
        let key = ele.key?.replace("staff_permission_", "")
        if (key?.includes("card_imports")) {
          key = ["card_imports", key.replace("card_imports_", "")]
        } else if (key?.includes("staff_users")) {
          key = ["staff_users", key.replace("staff_users_", "")]
        } else if (key?.includes("card_vendors")) {
          key = ["card_vendors", key.replace("card_vendors_", "")]
        } else {
          key = key.split("_")
        }
        if (permissionObj[key[0]]) {
          permissionObj[key[0]] = {
            ...permissionObj[key[0]],
            [key[1]]: ele.id
          }
        } else {
          permissionObj[key[0]] = {
            [key[1]]: ele.id
          }
        }
      })
      setPermission(permissionObj)
    })
    return () => {
      formik.resetForm()
      formik.setFieldValue("permission_ids", [])
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (add_staff?.status) {
      message.success("Added user");
      dispatch({
        type: AddStaffUser.RESET
      })
      dispatch(staffUserList())
      onCancel()
      formik.resetForm()
      formik.setFieldValue("permission_ids", [])
    }

    // eslint-disable-next-line
  }, [add_staff])

  useEffect(() => {
    if (selectedData?.id) {
      let permission_ids = selectedData?.permission_data?.map(ele => ele.id) || [];
      delete selectedData?.permission_data
      formik.setValues({
        ...selectedData,
        permission_ids
      })
    }
  }, [selectedData])

  return (
    <>
      <form className="modal-form staff" onSubmit={formik.handleSubmit}>
        <div className='input-div'>
          <label className='required'>Email</label>
          <Input disabled={selectedData?.id} autoComplete='off' placeholder="Enter Email" value={formik.values.email} onChange={formik.handleChange} name="email" />
          {formik.touched.email && formik.errors.email && <div className='error'>{formik.errors.email}</div>}
        </div>
        <div className='input-div'>
          <label>Name</label>
          <Input autoComplete='off' placeholder="Enter Name" value={formik.values.name} onChange={formik.handleChange} name="name" />
          {formik.touched.name && formik.errors.name && <div className='error'>{formik.errors.name}</div>}

        </div>
        <div className='input-div'>
          <label>Phone No</label>
          <Input maxLength={15} autoComplete='off' placeholder="Enter Phone No" value={formik.values.phone_no} onChange={(e) => {
            if (Number.isNaN(Number(e.target.value)) && e.target.value) {
              return
            }
            formik.setFieldValue("phone_no", e.target.value)
          }} name="phone_no" />
          {formik.touched.phone_no && formik.errors.phone_no && <div className='error'>{formik.errors.phone_no}</div>}

        </div>
        <div className='input-div'>
          <label>Designation</label>
          <Input autoComplete='off' placeholder="Enter Designation" value={formik.values.designation} onChange={formik.handleChange} name="designation" />
          {formik.touched.designation && formik.errors.designation && <div className='error'>{formik.errors.designation}</div>}
        </div>
        {!selectedData?.id && <div className='input-div'>
          <label>Password</label>
          <Input.Password autoComplete='off' placeholder="Enter Password" value={formik.values.password} onChange={formik.handleChange} name="password" />
          {formik.touched.password && formik.errors.password && <div className='error'>{formik.errors.password}</div>}
          <PasswordCheck dark={true} password={formik.values.password} setPasswordCheck={setPasswordCheck} />
        </div>}
        <div className='input-div'>
          <label>Permissions</label>
          {Object.keys(permissions).map(key => {
            return <div className='flex-between'>
              <b>
                {_.startCase(key)}
              </b>
              <div className="floatRightDiv">
                <p className="smallP">
                  Write
                  <Checkbox
                    checked={formik.values.permission_ids?.includes(permissions[key].write)}
                    onChange={(e) => {
                      let arr = formik.values.permission_ids;
                      let idx = arr?.indexOf(permissions[key].write)
                      if (idx > -1) {
                        arr.splice(idx, 1)
                      } else {
                        arr.push(permissions[key].write)
                      }
                      formik.setFieldValue("permission_ids", arr)
                    }}
                  />
                </p>
                <p className="smallP">
                  Read
                  <Checkbox
                    checked={formik.values.permission_ids?.includes(permissions[key].read)}
                    onChange={(e) => {
                      let arr = formik.values.permission_ids;
                      let idx = arr?.indexOf(permissions[key].read)
                      if (idx > -1) {
                        arr.splice(idx, 1)
                      } else {
                        arr.push(permissions[key].read)
                      }
                      formik.setFieldValue("permission_ids", arr)
                    }}
                  />
                </p>
              </div>
            </div>
          })}
          {formik.touched.permission_ids && formik.errors.permission_ids && <div className='error'>{formik.errors.permission_ids}</div>}
        </div>
        {add_staff?.errors &&
          <div className='error'>
            {add_staff?.errors}
          </div>}
        <div className='d-flex'>
          <Button key="1" htmlType="submit" className="formButton mt1" disabled={passwordCheck || add_staff_loading} loading={add_staff_loading}>Save</Button>
          <Button key="2" className="cancelButton ml1 mt1" onClick={() => {
            onCancel()
            formik.resetForm()
            formik.setFieldValue("permission_ids", [])
          }}>Cancel</Button>
        </div>
      </form>
    </>
  );
}

export default AddStaff
