import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../Common/Table/Table";
import PageHeader from "../../PageHeader";
import { get_card_vendors } from "../../../actions/cards/cards";
import AddCardVendor from "./AddCardVendor";
import { hasAccess } from "../../../utils";

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Outlet Id',
    dataIndex: "api_credentials",
    render: (text) => text.outletId,
  },
  {
    title: 'Branch Id',
    dataIndex: "api_credentials",
    render: (text) => text.branchId,
  }, {
    title: 'Tenant Id',
    dataIndex: "api_credentials",
    render: (text) => text.tenantId,
  }, {
    title: 'Channel Id',
    dataIndex: "api_credentials",
    render: (text) => text.channelId,
  },
  {
    title: 'Base Url',
    dataIndex: "api_credentials",
    render: (text) => text.base_url,
  },
  {
    title: 'Subscription key',
    dataIndex: "api_credentials",
    render: (text) => text.ocp_apim_subscription_key,
  },
];


const CardVendors = () => {
  const [addStaffModal, setAddStaffModal] = useState();
  const [filters, setFilters] = React.useState({});
  const data = useSelector(state => state?.cards?.get_card_vendors?.data?.response?.card_vendors);
  const cards_total = useSelector(state => state?.cards?.get_card_vendors?.data?.total_records);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(get_card_vendors())
    // eslint-disable-next-line
  }, [])

  const handleSearch = (value) => {
    dispatch(
      get_card_vendors({ page: 1, search: value })
    );
    setFilters({
      ...filters,
      search: value,
      page: 1,
    });
  };

  return (
    <>
      <div className="heading-div">
        <PageHeader
          title={`Card Vendors`} />
      </div>
      <Table
        columns={columns}
        data={data}
        addBtn={hasAccess("card_vendors", "write")}
        handleSearch={handleSearch}
        buttonText={"Add Card Vendor"}
        pagination={{
          total: cards_total || 0,
          onChange: (page) => {
            dispatch(get_card_vendors({ ...filters, page }));
          },
        }}
        handleButtonFunc={() => {
          setAddStaffModal(true)
        }} />
      <Modal
        visible={addStaffModal}
        title="Add Card Vendor"
        className="right-alinged-modal"
        footer={[]}
        closable={false}
      >
        {addStaffModal && <AddCardVendor onCancel={() => {
          setAddStaffModal(false)
        }} />}
      </Modal>
    </>
  );
};

export default CardVendors;
