import React, { useEffect, useRef } from "react";
import { useState } from "react";
import search from "../../assets/icons/search.svg";

const SearchInput = (props) => {
  const [input, setInput] = useState("");
  const timeout = useRef();
  useEffect(() => {

    timeout.current = setTimeout(() => {
      props.handleSearch && props.handleSearch(input);
    }, 600)

    return () => {
      clearTimeout(timeout.current)
    }
    // eslint-disable-next-line
  }, [input])

  return (
    <div className="search-input">
      <input
        placeholder={props.placeholder ? props.placeholder : "Search..."}
        value={input}
        onInput={(e) => setInput(e.target.value)}
      />
      <img
        src={search}
        alt="search"
        className="cursor"
        onClick={() => {
          props.handleSearch && props.handleSearch(input);
        }}
      />
    </div>
  );
};

export default SearchInput;
