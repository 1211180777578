import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Typography
} from "antd";
import Text from "antd/lib/typography/Text";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";
import {
  addStaffCompany,
  AddStaffCompany,
  base_currencies,
  getStaffCompanyDetails,
  StaffCompanyDetails
} from "../../../actions/clients/clients";
import Dropzone from "../../Dropzone";
import "../clients.css";
import { get_card_vendors, link_card_vendors } from "../../../actions/cards";
import PasswordCheck from "../../Common/PasswordCheck";

const { TextArea } = Input;
const { Title } = Typography;
// const { Dragger } = Upload;


// const certificateOfIncorporationFileUploadProps = {
//   name: "file",
//   multiple: true,
//   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
//   onChange(info) {
//     const { status } = info.file;
//     if (status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }
//     if (status === "done") {
//       message.success(`${info.file.name} file uploaded successfully.`);
//     } else if (status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
//   onDrop(e) {
//     console.log("Dropped files", e.dataTransfer.files);
//   },
// };

// const cancelledChequeFileUploadProps = {
//   name: "file",
//   multiple: true,
//   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
//   onChange(info) {
//     const { status } = info.file;
//     if (status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }
//     if (status === "done") {
//       message.success(`${info.file.name} file uploaded successfully.`);
//     } else if (status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
//   onDrop(e) {
//     console.log("Dropped files", e.dataTransfer.files);
//   },
// };

// const otherDocumentsFileUploadProps = {
//   name: "file",
//   multiple: true,
//   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
//   onChange(info) {
//     const { status } = info.file;
//     if (status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }
//     if (status === "done") {
//       message.success(`${info.file.name} file uploaded successfully.`);
//     } else if (status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
//   onDrop(e) {
//     console.log("Dropped files", e.dataTransfer.files);
//   },
// };

// const gstCertificateFileUploadProps = {
//   name: "file",
//   multiple: true,
//   action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
//   onChange(info) {
//     const { status } = info.file;
//     if (status !== "uploading") {
//       console.log(info.file, info.fileList);
//     }
//     if (status === "done") {
//       message.success(`${info.file.name} file uploaded successfully.`);
//     } else if (status === "error") {
//       message.error(`${info.file.name} file upload failed.`);
//     }
//   },
//   onDrop(e) {
//     console.log("Dropped files", e.dataTransfer.files);
//   },
// };

const OrganisationProfile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const addStaffCompRes = useSelector(
    (state) => state?.client?.addStaffCompany
  );

  const currencies = useSelector(
    (state) => state?.client?.currencies?.data?.response?.base_currencies
  );

  const compRes = useSelector((state) => state?.client?.getStaffCmp);
  const vendors = useSelector(state => state?.cards?.get_card_vendors?.data?.response?.card_vendors);
  const location = useLocation();

  const [isAuto, setIsAuto] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState(false);

  const OrgProfileSchema = Yup.lazy((vals) => {
    let obj = {
      name: Yup.string().required("Name is required."),
      mo_id: Yup.string().required("Mo ID is required."),
      virtual_id: Yup.string().required("Virtual ID is required."),
      outlet_id: Yup.string().required("Outlet ID is required."),
      registration_no: Yup.string().required("Registration no is required."),
      logo: Yup.mixed(),
      address: Yup.string().required("Address is required."),
      city: Yup.mixed(),
      state: Yup.mixed(),
      country: Yup.mixed(),
      pincode: Yup.mixed(),
      office_no: Yup.string().required("Office no is required."),
      gst_no: Yup.string().required("Gst no is required."),
      tan_no: Yup.string().required("Tan no is required."),
      primary_email: Yup.string()
        .email("Enter valid primary email.")
        .required("Primary email is required."),
      email: Yup.string()
        .email("Enter valid email."),
      primary_phone: Yup.string().required("Primary phone is required."),
    };
    if (!isAuto && !vals.id) {
      obj = {
        ...obj,
        password_confirmation: Yup.string()
          .required("Password confirmation is required.")
          .oneOf([Yup.ref("password"), null], "Passwords must match"),
        password: Yup.string().required("Password is required."),
      };
    } else {
      if (obj.password && obj.password_confirmation) {
        delete obj.password;
        delete obj.password_confirmation;
      }
    }
    return Yup.object().shape(obj);
  });

  const initialObj = {
    name: "",
    title: "Mr.",
    email: "",
    registration_no: "",
    logo: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    office_no: "",
    gst_no: "",
    tan_no: "",
    primary_email: "",
    primary_phone: "",
    password_confirmation: "",
    password: "",
    mo_id: "",
    virtual_id: "",
    outlet_id: "",
    account_name: "",
    account_number: "",
    account_ifsc: "",
    bank_name: "",
    bank_address: "",
    bank_branch: "",
    base_currency_id: "",
    vendor_id: ""
  };

  const formik = useFormik({
    initialValues: initialObj,
    validationSchema: OrgProfileSchema,
    onSubmit: (vals) => {
      let obj = { ...vals }
      if (isAuto) {
        delete obj.password
        delete obj.password_confirmation
      }
      if (typeof obj.logo === "string" || !obj.logo) {
        delete obj.logo;
      }
      if (typeof obj.gst_certificate === "string" || !obj.gst_certificate) {
        delete obj.gst_certificate;
      }
      if (typeof obj.incorporation === "string" || !obj.incorporation) {
        delete obj.incorporation;
      }
      if (typeof obj.other === "string" || !obj.other) {
        delete obj.other;
      }
      if (typeof obj.cheque === "string" || !obj.cheque) {
        delete obj.cheque;
      }
      if (typeof obj.address_proof === "string" || !obj.address_proof) {
        delete obj.address_proof;
      }
      dispatch(addStaffCompany(obj));
    },
  });

  useEffect(() => {
    if (addStaffCompRes?.success) {
      message.success(addStaffCompRes?.data?.message);
      if (formik.values.vendor_id) {
        dispatch(link_card_vendors({
          company_id: addStaffCompRes?.data?.response?.company?.id,
          card_vendor_id: formik.values.vendor_id
        }))
      }
      dispatch({
        type: AddStaffCompany.RESET
      })
      formik.resetForm();
      props.history.push("/admin/clients");
    }

    if (addStaffCompRes?.success === false) {
      message.error(addStaffCompRes?.data?.errors ?? addStaffCompRes?.data?.message);
      dispatch({
        type: AddStaffCompany.RESET
      })
    }
    // eslint-disable-next-line
  }, [addStaffCompRes]);

  useEffect(() => {
    if (location?.state && location?.state?.id) {
      dispatch(getStaffCompanyDetails(location?.state?.id));
    }
    // eslint-disable-next-line
  }, [location?.state]);

  useEffect(() => {
    if (compRes && compRes?.success) {
      delete compRes?.data?.response?.company?.financial_year
      delete compRes?.data?.response?.company?.time_zone
      let base_currency_id = compRes?.data?.response?.company?.base_currency?.id
      delete compRes?.data?.response?.company?.base_currency
      formik.setValues({
        ...initialObj,
        ...compRes?.data?.response?.company,
        vendor_id: compRes?.data?.response?.company?.card_vendor_id,
        base_currency_id
      });
    }
    // eslint-disable-next-line
  }, [compRes]);

  useEffect(() => {
    dispatch(get_card_vendors())
    dispatch(base_currencies())
    return () => {
      formik.setValues(initialObj);
      dispatch({
        type: StaffCompanyDetails.RESET
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="contentWrapper" style={{ height: "100%", flex: 1 }}>
        <Title level={5} className="cardTitle">
          Organisation Profile
        </Title>
        <Divider />
        <Form
          layout="vertical"
          onFinish={formik.handleSubmit}
          className="settings_form"
        >
          <Row>
            <Col span={12}>
              <Form.Item label="Company Name" required>
                <Input
                  placeholder="Company Name"
                  className="inputTag"
                  name="name"
                  value={formik.values["name"]}
                  onChange={formik.handleChange}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="err-msg">{formik.errors.name}</div>
                )}
              </Form.Item>
              <Form.Item label="Primary Company Email" required>
                <Input
                  placeholder="Primary Company Email"
                  className="inputTag"
                  name="primary_email"
                  value={formik.values["primary_email"]}
                  onChange={formik.handleChange}
                />
                {formik.touched.primary_email && formik.errors.primary_email && (
                  <div className="err-msg">{formik.errors.primary_email}</div>
                )}
              </Form.Item>
              <Form.Item label="Company Registration No" required>
                <Input
                  placeholder="Company Registration No"
                  className="inputTag"
                  name="registration_no"
                  value={formik.values["registration_no"]}
                  onChange={formik.handleChange}
                />
                {formik.touched.registration_no &&
                  formik.errors.registration_no && (
                    <div className="err-msg">
                      {formik.errors.registration_no}
                    </div>
                  )}
              </Form.Item>
              <Form.Item label="Address" required>
                <TextArea
                  name="address"
                  value={formik.values["address"]}
                  // onChange={this.onChange}
                  placeholder="Enter Address"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  className="inputTag"
                  onChange={
                    (e) => {
                      if (!/^[A-Za-z0-9$&,:;@#.-/ ]*$/.test((e.target.value))) {
                        return;
                      }
                      formik.setFieldValue("address", e.target.value)
                    }

                  }
                />
                {formik.touched.address && formik.errors.address && (
                  <div className="err-msg">{formik.errors.address}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={12} className="uploadSection align-items-center">
              <Text>Upload your company logo</Text>
              <Dropzone
                file={formik.values["logo"]}
                acceptedFileTypes={{
                  'image/png': ['.jpeg', '.jpg', '.png'],
                }}
                setFile={(file) => {
                  formik.setFieldValue("logo", file);
                }}
                logo={formik.values["logo"]}
              />
              <p className="hint">
                Preferred Image Size: 240px x 240px @ 72 DPI Maximum size of
                1MB.
              </p>
              {formik.touched.logo && formik.errors.logo && (
                <div className="err-msg">{formik.errors.logo}</div>
              )}
            </Col>
          </Row>
          <Row className="gap1">
            <Col span={5}>
              <Form.Item label="City" name="city" >
                <Input
                  placeholder="City"
                  className="inputTag"
                  name="city"
                  value={formik.values["city"]}
                  onChange={(e) => {
                    if (!/^[A-Za-z ]*$/.test(e.target.value) && e.target.value) {
                      return;
                    }
                    formik.setFieldValue("city", e.target.value)
                  }}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="err-msg">{formik.errors.city}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="State" name="state">
                <Input
                  placeholder="State"
                  className="inputTag"
                  name="state"
                  value={formik.values["state"]}
                  onChange={(e) => {
                    if (!/^[A-Za-z ]*$/.test(e.target.value) && e.target.value) {
                      return;
                    }
                    formik.setFieldValue("state", e.target.value)
                  }}
                />
                {formik.touched.state && formik.errors.state && (
                  <div className="err-msg">{formik.errors.state}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Country" name="country">
                <Input
                  placeholder="Country"
                  className="inputTag"
                  name="country"
                  value={formik.values["country"]}
                  onChange={(e) => {
                    if (!/^[A-Za-z ]*$/.test(e.target.value) && e.target.value) {
                      return;
                    }
                    formik.setFieldValue("country", e.target.value)
                  }}
                />
                {formik.touched.country && formik.errors.country && (
                  <div className="err-msg">{formik.errors.country}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item label="Pincode" name="pincode">
                <Input
                  placeholder="Pincode"
                  className="inputTag"
                  name="pincode"
                  value={formik.values["pincode"]}
                  onChange={(e) => {
                    if (!/^[0-9]*$/.test(e.target.value) && e.target.value) {
                      return;
                    }
                    formik.setFieldValue("pincode", e.target.value)
                  }}
                />
                {formik.touched.pincode && formik.errors.pincode && (
                  <div className="err-msg">{formik.errors.pincode}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row className="gap1">
            <Col span={6}>
              <Form.Item label="Office Number" name="office_no" required>
                <Input
                  placeholder="Office Number"
                  className="inputTag"
                  name="office_no"
                  value={formik.values["office_no"]}
                  onChange={(e) => {
                    if (!/^[0-9]*$/.test(e.target.value) && e.target.value) {
                      return;
                    }
                    formik.setFieldValue("office_no", e.target.value)
                  }}
                />
                {formik.touched.office_no && formik.errors.office_no && (
                  <div className="err-msg">{formik.errors.office_no}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="GSTIN No" name="gst_no" required>
                <Input
                  placeholder="GSTIN No"
                  className="inputTag"
                  name="gst_no"
                  value={formik.values["gst_no"]}
                  onChange={
                    (e) => {
                      if (!/^[A-Za-z0-9]*$/.test((e.target.value))) {
                        return;
                      }
                      formik.setFieldValue("gst_no", e.target.value?.toUpperCase())
                    }

                  }
                />
                {formik.touched.gst_no && formik.errors.gst_no && (
                  <div className="err-msg">{formik.errors.gst_no}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="TAN No" name="tag_no" required>
                <Input
                  placeholder="TAN No"
                  className="inputTag"
                  name="tan_no"
                  value={formik.values["tan_no"]}
                  onChange={
                    (e) => {
                      if (!/^[A-Za-z0-9]*$/.test((e.target.value))) {
                        return;
                      }
                      formik.setFieldValue("tan_no", e.target.value?.toUpperCase())
                    }

                  }
                />
                {formik.touched.tan_no && formik.errors.tan_no && (
                  <div className="err-msg">{formik.errors.tan_no}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Title level={5} className="cardTitle">
            Organisation Ids
          </Title>
          <Divider />
          <Row className="gap1">
            <Col span={6}>
              <Form.Item label="Card Vendor" name="vendor_id" >
                <Select name="vendor_id"
                  placeholder="Select Card Vendor"
                  value={formik.values["vendor_id"]}
                  onChange={(val) => {
                    formik.setFieldValue("vendor_id", val)
                  }}>
                  {vendors?.map(ele =>
                    <Select.Option value={ele.id}>
                      {ele.name} - {ele?.api_credentials?.tenantId}
                    </Select.Option>
                  )}
                </Select>
                {formik.touched.vendor_id && formik.errors.vendor_id && (
                  <div className="err-msg">{formik.errors.vendor_id}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="MO Id" name="mo_id" required>
                <Input
                  placeholder="MO Id"
                  className="inputTag"
                  name="mo_id"
                  value={formik.values["mo_id"]}
                  onChange={formik.handleChange}
                  disabled={location?.state?.id}
                />
                {formik.touched.mo_id && formik.errors.mo_id && (
                  <div className="err-msg">{formik.errors.mo_id}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Virtual ID" name="virtual_id" required>
                <Input
                  placeholder="Virtual ID"
                  className="inputTag"
                  name="virtual_id"
                  value={formik.values["virtual_id"]}
                  onChange={formik.handleChange}
                  disabled={location?.state?.id}
                />
                {formik.touched.virtual_id && formik.errors.virtual_id && (
                  <div className="err-msg">{formik.errors.virtual_id}</div>
                )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Outlet ID" name="outlet_id">
                <Input
                  placeholder="Outlet ID"
                  className="inputTag"
                  name="outlet_id"
                  value={formik.values["outlet_id"]}
                  onChange={formik.handleChange}
                  disabled={location?.state?.id}
                />
                {formik.touched.outlet_id && formik.errors.outlet_id && (
                  <div className="err-msg">{formik.errors.outlet_id}</div>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Title level={5} className="cardTitle">
            Primary Contact
          </Title>
          <Divider />
          <Row className="gap1">
            <Col span={6}>
              <Form.Item label="Primary Contact Name" className="primary-contact" required>
                <Select name="title"
                  placeholder="Title"
                  value={formik.values["title"]}
                  disabled={location?.state?.id}
                  onChange={(val) => {
                    formik.setFieldValue("title", val)
                  }}>
                  <Select.Option value={"Ms."}>Ms.</Select.Option>
                  <Select.Option value={"Mr."}>Mr.</Select.Option>
                </Select>
                <Input
                  placeholder="Primary Contact Name"
                  className="inputTag"
                  name="primary_contact_name"
                  value={formik.values["primary_contact_name"]}
                  onChange={formik.handleChange}
                  disabled={location?.state?.id}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Primary Contact Number" name="contact_number" required>
                <Input
                  placeholder="Primary Contact Number"
                  className="inputTag"
                  name="primary_phone"
                  value={formik.values["primary_phone"]}
                  disabled={location?.state?.id}
                  maxLength={15}
                  onChange={(e) => {
                    if (!/^[0-9]*$/.test(e.target.value) && e.target.value) {
                      return;
                    }
                    formik.setFieldValue("primary_phone", e.target.value)
                  }}
                />
                {formik.touched.primary_phone &&
                  formik.errors.primary_phone && (
                    <div className="err-msg">{formik.errors.primary_phone}</div>
                  )}
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Contact Email" name="contact_email" required>
                <Input
                  placeholder="Contact Email"
                  className="inputTag"
                  disabled={location?.state?.id}
                  name="email"
                  value={formik.values["email"]}
                  onChange={formik.handleChange}
                />
                {formik.touched.email &&
                  formik.errors.email && (
                    <div className="err-msg">{formik.errors.email}</div>
                  )}
              </Form.Item>
            </Col>
          </Row>
          {!formik.values?.id && <>
            <Title level={5} className="cardTitle">
              Password
            </Title>
            <Divider />
            <Row>
              <Col>
                <div className="radio-grp mb1">
                  <Input
                    type={"radio"}
                    name="isAuto"
                    onChange={() => {
                      setIsAuto(true);
                      formik.setFieldValue("password", "");
                      formik.setFieldValue("password_confirmation", "");
                    }}
                    checked={isAuto}
                  />
                  <div>
                    <label>Automatically Generate Password</label>
                    <span>
                      Email will be sent to the client to set the password.
                    </span>
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="radio-grp">
                  <Input
                    type={"radio"}
                    checked={!isAuto}
                    name="isAuto"
                    onChange={() => {
                      setIsAuto(false);
                    }}
                  />
                  <label>Create Password</label>
                </div>
                {!isAuto && (
                  <Row className="gap1 mt1">
                    <Col span={6}>
                      <Form.Item label="Password" name="contact_number" required>
                        <Input.Password
                          placeholder="Password"
                          type={"password"}
                          className="inputTag"
                          name="password"
                          value={formik.values["password"]}
                          onChange={formik.handleChange}
                        />
                        <PasswordCheck password={formik.values.password} dark setPasswordCheck={setPasswordCheck} />
                      </Form.Item>
                    </Col>
                    <Col span={6}>
                      <Form.Item
                        label="Confirm Password"
                        name="password_confirmation"
                        required
                      >
                        <Input.Password
                          placeholder="Confirm Password"
                          type={"password"}
                          className="inputTag"
                          name="password_confirmation"
                          value={formik.values["password_confirmation"]}
                          onChange={formik.handleChange}
                        />
                        {formik.touched.password_confirmation &&
                          formik.errors.password_confirmation && (
                            <div className="err-msg">
                              {formik.errors.password_confirmation}
                            </div>
                          )}
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </>}
          <Title level={5} className="cardTitle mt5">
            Regional Settings
          </Title>
          <Divider />
          <Row>
            <Col span={12}>
              <Form.Item label="Base Currency">
                <Select
                  onChange={(val) => {
                    formik.setFieldValue("base_currency_id", val)
                  }}
                  placeholder="Base Currency" className="inputTag" options={currencies?.length ? currencies?.map(ele => ({
                    label: ele.name,
                    value: ele.id
                  })) : []}
                  value={formik.values.base_currency_id}
                />
              </Form.Item>
            </Col>
          </Row>
          <Title level={5} className="mt2">
            Bank Details
          </Title>
          <Divider />
          <Row className="gap1">
            <Col span={7}>
              <Form.Item label="Account name">
                <Input placeholder="Account name"
                  name="account_name"
                  className="inputTag"
                  value={formik.values["account_name"]}
                  onChange={formik.handleChange} />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Account IFSC">
                <Input placeholder="Account IFSC"
                  name="account_ifsc"
                  className="inputTag"
                  value={formik.values["account_ifsc"]}
                  onChange={formik.handleChange} />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Account Number">
                <Input placeholder="Account Number"
                  name="account_number"
                  className="inputTag"
                  value={formik.values["account_number"]}
                  onChange={formik.handleChange} />
              </Form.Item>
            </Col>
          </Row>
          <Row className="gap1">
            <Col span={7}>
              <Form.Item label="Bank name">
                <Input placeholder="Bank name"
                  name="bank_name"
                  className="inputTag"
                  value={formik.values["bank_name"]}
                  onChange={formik.handleChange} />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Bank branch">
                <Input placeholder="Bank branch"
                  name="bank_branch"
                  className="inputTag"
                  value={formik.values["bank_branch"]}
                  onChange={formik.handleChange} />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Bank address">
                <Input placeholder="Bank address"
                  name="bank_address"
                  className="inputTag"
                  value={formik.values["bank_address"]}
                  onChange={formik.handleChange} />
              </Form.Item>
            </Col>
          </Row>
          <div className="d-flex flex-end">
            <Button
              key="2"
              className="cancelButton ml1 mt1"
              onClick={() => history.push(
                "/admin/clients/"
              )}
              style={{ width: "100px" }}
              disabled={addStaffCompRes?.loading}
            >
              Cancel
            </Button>
            <Button
              key="1"
              htmlType="submit"
              className="formButton mt1 ml2"
              style={{ width: "100px" }}
              disabled={addStaffCompRes?.loading || passwordCheck}
            >
              {addStaffCompRes?.loading ? "Creating..." : "Create"}
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default withRouter(OrganisationProfile);
