import { ActionTypesFactory } from "../../utils";

export const AddCompany = ActionTypesFactory("AddCompany", "Company");
export const CompanyList = ActionTypesFactory("Company", "CompanyList");
export const CompanyEnable = ActionTypesFactory("Company", "CompanyEnable");
export const BaseCurrencies = ActionTypesFactory("Company", "BaseCurrencies");

export const AddStaffCompany = ActionTypesFactory("StaffCompany", "Company");
export const Leads = ActionTypesFactory("Company", "Leads");

export const StaffCompanyDetails = ActionTypesFactory(
  "StaffCompany",
  "CompanyDetails"
);
export const StaffCompanyList = ActionTypesFactory(
  "StaffCompany",
  "CompanyList"
);

export const addCompany = (payload) => {
  return {
    type: AddCompany.REQUEST,
    payload,
  };
};

export const base_currencies = (payload) => {
  return {
    type: BaseCurrencies.REQUEST,
    payload,
  };
};

export const getLeads = (payload) => {
  return {
    type: Leads.REQUEST,
    payload,
  };
};

export const getCompanyList = (payload) => {
  return {
    type: CompanyList.REQUEST,
    payload,
  };
};

export const addStaffCompany = (payload) => {
  return {
    type: AddStaffCompany.REQUEST,
    payload,
  };
};

export const getStaffCompanyList = (payload) => {
  return {
    type: StaffCompanyList.REQUEST,
    payload,
  };
};

export const enableStaffCompany = (payload) => {
  return {
    type: CompanyEnable.REQUEST,
    payload,
  };
};

export const getStaffCompanyDetails = (payload) => {
  return {
    type: StaffCompanyDetails.REQUEST,
    payload,
  };
};
