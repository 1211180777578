export const apis = {
  login: `/api/auth/login`,
  reports: `/api/reports/`,
  categories: `/api/admin/categories/`,
  categoriesStatus: `/api/admin/categories/change_status/`,
  deleteCategories: `/api/admin/categories/delete/`,
  employees: `/api/admin/employees/`,
  companies: `/api/admin/companies/`,
  clientList: "/api/staff/companies/list",
  leads: "/api/staff/users/free_demo_inquiry_list",
  staffLogin: "/api/staff/login",
  staffCompanies: "/api/staff/companies",
  staffUpdateCompanies: "/api/staff/companies/update",
  staffCompanyDetails: "/api/staff/companies/show",
  staffEnableCompany: "/api/staff/companies/enable_company",
  staffUserList: "/api/staff/users/list",
  staffUserExportList: "/api/staff/users/export_user_list_csv",
  clientExportList: "/api/staff/companies/export_company_list_csv",
  staffCards: "/api/staff/cards/list",
  staffCardImports: "/api/staff/cards/imports",
  staffCardDownloadFile: "/api/staff/cards/download_file",
  staffLogout: "/api/staff/logout",

  staffUser: "/api/staff/staff_users",
  staff_users_delete: "/api/staff/staff_users/delete_staff",
  staff_users_update: "/api/staff/staff_users/update",
  base_currencies: `/api/staff/base_currencies`,
  staffPermissions: `/api/staff/fetch_permission`,
  resetPassword: '/api/staff/companies/user/send_password_reset_email',
  card_vendors: "/api/staff/card_vendors",
  link_card_vendor: "/api/staff/card_vendors/link_card_vendor",
  unlink_card_vendor: "/api/staff/card_vendors/unlink_card_vendor",
  delete_vendor: "/api/staff/card_vendors/delete"
};
