import React from "react";
import PageHeader from "../PageHeader";
import CardDetailsList from "../Users/EditUser/CardDetailsList";

const CardsDashboard = () => {
  return (
    <>
      <div className="heading-div">
        <PageHeader
          title={`Cards`}
        />
      </div>
      <CardDetailsList />
    </>
  );
};

export default CardsDashboard;
