import { DownSquareOutlined, FormOutlined } from "@ant-design/icons";
import { Dropdown, Menu, message, Switch } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { useHistory } from "react-router-dom";
import {
  CompanyEnable,
  enableStaffCompany,
  getCompanyList,
} from "../../actions/clients/clients";
import json2csv from 'json2csv';
import { ClientListExport } from "../../actions/employees/employees";
import { apis } from "../../config/APIs";
import endpoint from "../../config/Axios";
import { hasAccess } from "../../utils";
import Table from "../Common/Table/Table";
import PageHeader from "../PageHeader";
import "./clients.css";
import { saveAs } from "file-saver";

const menu = (email) => (
  <Menu>
    {/* <Menu.Item style={{ padding: 10 }}>
      Send Invitation
    </Menu.Item>
    <hr /> */}
    <Menu.Item key={1} onClick={() => {

      endpoint.post(apis.resetPassword + `?email=${email}`).then(res => {
        message.success("Reset password link has been sent to your email.")

      }).catch(err => {
        message.error("Failed to send email.")


      })
    }} style={{ padding: 10 }}>
      Resend Password
    </Menu.Item>
  </Menu>
);
const ClientDashboard = () => {
  const dispatch = useDispatch();
  const [filters, setFilters] = React.useState({});
  const client_list_export = useSelector((state) => state?.client?.export);
  const client_data = useSelector(
    (state) => state?.client?.getClientList?.data?.response?.companies
  );
  const history = useHistory();
  const client_enable = useSelector((state) => state?.client?.enalbeCmp);

  const totalRecords = useSelector(
    (state) => state?.client?.getClientList?.data?.total_records
  );
  const columns = [
    {
      title: "S.NO",
      dataIndex: "series",
      key: "series",
    },
    {
      title: "Company Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Mobile Number",
      dataIndex: "primary_phone",
      key: "primary_phone",
    },
    {
      title: "Email",
      dataIndex: "primary_email",
      key: "primary_email",
    },
    {
      title: "Contact person",
      dataIndex: "primary_contact_name",
      key: "primary_contact_name",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => <div>{moment(text, "DD MMM'YY, HH:mm:ss a").format("DD MMM YYYY")}</div>,
    },
    // {
    //   title: "Last Login",
    //   dataIndex: "last_login",
    //   key: "last_login",
    //   render: (text) => <div>{moment(text).format("DD MMM YYYY")}</div>,
    // },
    {
      title: "MO ID",
      dataIndex: "mo_id",
      key: "mo_id",
    },
    {
      title: "Outlet ID",
      dataIndex: "outlet_id",
      key: "outlet_id",
    },
    {
      title: "Virtual ID",
      dataIndex: "virtual_id",
      key: "virtual_id",
    },
    {
      title: "Actions",
      dataIndex: "id",
      fixed: "right",
      width: 100,
      key: "id",
      render: (text, record) => (
        <div className="actionsCard">
          <Switch
            defaultChecked={record.enabled}
            onChange={(val) => onChange(val, text)}
            disabled={!hasAccess("clients", "write")}
          />

          <button
            className="editButtonDiv"
            style={{ color: "#454545" }}
            onClick={() => {
              history.push({
                pathname: "/admin/clients/add-new-client/organisation-profile",
                state: {
                  id: text,
                },
              })
            }}
            disabled={!hasAccess("clients", "write")}
          >
            <FormOutlined />
            &nbsp;&nbsp;Edit
          </button>
          <Dropdown
            disabled={!hasAccess("clients", "write")}
            overlay={menu(record.primary_email)} placement="bottomRight" arrow>
            <div className="expandCardIconDiv">
              <DownSquareOutlined />
            </div>
          </Dropdown>
        </div>
      ),
    },
  ];


  React.useEffect(() => {
    if (client_list_export?.success) {
      window.open(client_list_export?.data?.response["CSV File URL"], "_blank");
      dispatch({
        type: ClientListExport.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_list_export]);

  React.useEffect(() => {
    dispatch(getCompanyList({
      page: 1
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (client_enable?.success) {
      dispatch(getCompanyList({
        page: 1
      }));
      dispatch({
        type: CompanyEnable.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_enable]);

  const handleSearch = (value) => {
    dispatch(getCompanyList({ search: value }));
    setFilters({
      ...filters,
      search: value,
    });
  };

  const onChange = (checked, id) => {
    dispatch(
      enableStaffCompany({
        cmpId: id,
        enabled: checked,
      })
    );
  };

  return (
    <>
      <div className="heading-div">
        <PageHeader
          title={`Clients`}
        />
      </div>
      <Table
        columns={columns}
        placeholder={"Search by Company Name, Mobile, Email"}
        data={client_data ? client_data : []}
        isTopFilter={true}
        handleSearch={handleSearch}
        headerButtonProps={{
          buttonName: "+ Add New Client",
          route: "/admin/clients/add-new-client/organisation-profile",
        }}
        isHeaderButton={hasAccess("clients", "write")}
        pagination={{
          total: totalRecords || 0,
          onChange: (pagee) =>
            dispatch(getCompanyList({ page: pagee, ...filters })),
        }}
        topFilters={[]}
        exportToExcel={() => {
          endpoint.get(apis.clientList, {
            params: {
              csv: true
            }
          }).then(res => {
            let cli_data = res?.data?.response?.companies
            let csv = json2csv.parse(cli_data?.map(ele => {
              let obj = JSON.parse(JSON.stringify(ele))
              delete obj.id
              delete obj.base_currency
              delete obj.branches
              obj.currency = ele.base_currency?.iso_code
              return obj
            }));
            var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
            saveAs(blob, "clients.csv");
          })

        }}
      />
    </>
  );
};

export default withRouter(ClientDashboard);
