import { message } from "antd";
import axios from "axios";

export const APP_BASE_URL = window.location.host?.includes("emsweb") ? "https://emsapi.paycraftsol.com:10443" : "https://ems-appuat.paycraftsol.com";
// export const APP_BASE_URL = "http://192.168.5.16:3000";

const endpoint = axios.create({
  baseURL: APP_BASE_URL,
});

endpoint.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    config.headers.Authorization = "Bearer " + localStorage.getItem("token");
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

endpoint.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error?.response?.status === 422 && error?.response.data?.message === "Signature has expired") {
      message.error(<span className="messageText">Session Expired.</span>)
      localStorage.clear()
      window.location.href = "/admin/login"
    };
    return Promise.reject(error);
  }
);

export default endpoint;
