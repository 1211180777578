import {
  Modal, Select
} from "antd";
import json2csv from 'json2csv';
import moment from "moment";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { getCompanyList } from "../../actions/clients/clients";
import {
  UsersListExport,
  usersList
} from "../../actions/employees/employees";
import Table from "../Common/Table/Table";
import PageHeader from "../PageHeader";
import { saveAs } from "file-saver";
import endpoint from "../../config/Axios";
import { apis } from "../../config/APIs";

// const menu = (
//   <Menu>
//     {/* <Menu.Item style={{ padding: 10 }}>
//       <a target="_blank" rel="noopener noreferrer" href="#send_invitation">
//         Send Invitation
//       </a>
//     </Menu.Item> */}
//     {/* <hr /> */}
//     <Menu.Item style={{ padding: 10 }}>
//       <a target="_blank" rel="noopener noreferrer" href="#auto_signup">
//         Auto Sign Up
//       </a>
//     </Menu.Item>
//     <hr />
//     <Menu.Item style={{ padding: 10 }}>
//       <a target="_blank" rel="noopener noreferrer" href="#generate_password">
//         Generate Password
//       </a>
//     </Menu.Item>
//   </Menu>
// );
const UserDashboard = () => {
  const dispatch = useDispatch();
  const users_list = useSelector((state) => state?.users?.list);
  const users_list_export = useSelector((state) => state?.users?.export);
  const [filters, setFilters] = React.useState({});
  const [companyModal, setCompanyModal] = React.useState(true);
  const [company, setCompany] = React.useState("");
  const client_data = useSelector(
    (state) =>
      state?.client?.getClientList?.data?.response?.companies?.map(ele => ele?.name)
  );
  React.useEffect(() => {
    dispatch(usersList({ page: 1 }));
    dispatch(getCompanyList({ page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (users_list_export?.success) {
      window.open(users_list_export?.data?.response["CSV File URL"], "_blank");
      dispatch({
        type: UsersListExport.RESET,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users_list_export]);
  const handleSearch = (value) => {
    dispatch(
      usersList({ ...filters, page: 1, search: value, company })
    );
    setFilters({
      ...filters,
      search: value,
      page: 1
    });
  };

  const columns = [
    {
      title: "S.NO",
      dataIndex: "id",
      key: "id",
      render: (text, data, idx) => {
        return <div className={`status-line`}>{idx + 1}</div>;
      },
    },
    {
      title: "Company Name",
      dataIndex: "company",
      key: "company",
      className: "user-col",
    },
    {
      title: "Employee Name",
      dataIndex: "name",
      key: "name",
      className: "user-col",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      className: "user-col",
    },
    {
      title: "Mobile",
      dataIndex: "primary_phone",
      key: "primary_phone",
      className: "user-col",
    },
    // {
    //   title: "Client",
    //   dataIndex: "client",
    //   className: "user-col",
    //   key: "client",
    // },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      className: "user-col",
      render: (text) => <div>{moment(text, "DD MMM'YY, HH:mm:ss a").format("DD MMM YYYY")}</div>,
    },
    {
      title: "Last Login",
      dataIndex: "last_login",
      className: "user-col",
      key: "last_login",
      render: (text) => <div>{text ? moment(text, "DD MMM'YY, HH:mm:ss a").format("DD MMM YYYY") : "-"}</div>,
    },
    {
      title: "Role",
      dataIndex: "role",
      className: "user-col",
      key: "role",
    },
    {
      title: "Status",
      dataIndex: "status",
      className: "user-col",
      key: "status",
    },
    // {
    //   title: "Actions",
    //   dataIndex: "actions",
    //   fixed: "right",
    //   width: 100,
    //   key: "actions",
    //   render: () => (
    //     <div className="actionsCard">
    //       <Dropdown overlay={menu} placement="bottomRight" arrow>
    //         <div className="expandCardIconDiv">
    //           <DownSquareOutlined />
    //         </div>
    //       </Dropdown>
    //     </div>
    //   ),
    // },
  ];

  const topFilters = [
    {
      label: "Status",
      action: (value) => {
        dispatch(
          usersList({
            ...filters,
            page: 1,
            status: value === "all" ? "" : value,
            company
          })
        );
        setFilters({
          ...filters,
          page: 1,
          status: value,
          company
        });
      },
      type: "dropdown",
      options: ["all", "active", "deleted"],
    },
    {
      label: company ? company : "Company",
      actionSearch: (value) => {
        dispatch(getCompanyList({ search: value }));
      },
      action: (value) => {
        dispatch(
          usersList({
            ...filters,
            page: 1,
            company: value,
          })
        );
        setFilters({
          ...filters,
          page: 1,
        });
        setCompany(value)
      },
      type: "dropdown",
      options: client_data ? client_data : [],
      searchOption: true,
    },
  ]

  return (
    <>
      <Modal
        visible={companyModal}
        title="Select your Company"
        onCancel={() => {
          setCompanyModal(false)
        }}
        footer={[<button className="primary-btn" onClick={() => {
          setCompanyModal(false)
        }}>
          Ok
        </button>]}
      >
        <Select
          style={{ width: "20rem" }}
          onSearch={(val) => {
            dispatch(getCompanyList({ search: val }));
          }}
          showSearch
          onChange={(val) => {
            setCompany(val)
            dispatch(
              usersList({
                ...filters,
                page: 1,
                company: val,
              })
            );
            setFilters({
              ...filters,
              company: val,
              page: 1
            })
          }} options={client_data?.map(ele => ({ label: ele, value: ele }))} />
      </Modal>
      <div className="heading-div">
        <PageHeader
          title={`Users`}
        />
      </div>
      <Table
        columns={columns}
        placeholder={"Search by Employee Name, Mobile, Email"}
        data={users_list?.data?.response?.users}
        isTopFilter={true}
        handleSearch={handleSearch}
        topFilters={topFilters}
        company={company}
        pagination={{
          showTotal: (total, range) => {
            return `Showing ${range[0]} to ${range[1]} of ${total} entires`;
          },
          total: users_list?.data?.total_records || 0,
          activePage: filters.page,
          onChange: useCallback((page) => {
            dispatch(usersList({
              ...filters,
              page,
              company
            }));
            setFilters({
              ...filters,
              page,
            });
            // eslint-disable-next-line
          }, [filters, company]),
        }}
        exportToExcel={() => {
          endpoint.get(apis.staffUserList, {
            params: {
              csv: true
            }
          }).then(res => {
            let usrs = res?.data?.response?.users
            let csv = json2csv.parse(usrs?.map(ele => {
              delete ele.id
              return ele
            }));
            var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
            saveAs(blob, "users.csv");
          })

        }}
      />
    </>
  );
};

export default withRouter(UserDashboard);
