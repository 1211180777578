import {
  // Menu, 
  Table
} from "antd";
import React from "react";
import { withRouter } from "react-router";
import SearchInput from "../SearchInput";
import ShowMoreLess from "./showMore";
import "./table.css";

// const menu = (
//   <Menu>
//     <Menu.Item style={{ padding: 10 }}>
//       <a target="_blank" rel="noopener noreferrer" href="#send_invitation">
//         Send Invitation
//       </a>
//     </Menu.Item>
//     <hr />
//     <Menu.Item style={{ padding: 10 }}>
//       <a target="_blank" rel="noopener noreferrer" href="#auto_signup">
//         Auto Sign Up
//       </a>
//     </Menu.Item>
//     <hr />
//     <Menu.Item style={{ padding: 10 }}>
//       <a target="_blank" rel="noopener noreferrer" href="#generate_password">
//         Generate Password
//       </a>
//     </Menu.Item>
//   </Menu>
// );

const TableWrap = ({
  columns,
  data,
  pagination,
  handleSearch,
  exportToExcel,
  hideExportButton,
  scroll,
  ...props
}) => {
  const handleButtonFunc = (route) => {
    if (route) {
      props.history.push(route);
    }
  };

  return (
    <div className="paycraft-table">
      {/* <div className="d-flex flex-between w100 mb1">
       
        {props.isBulkOperations && (
          <span className="bulk-ops">
            Bulk Operations
            <Dropdown overlay={menu} placement="bottomRight" arrow>
              <button className="ml1">
                <img src={Arrow} alt="arrow" width={8} />
              </button>
            </Dropdown>
          </span>
        )}
      </div> */}

      {props.isTopFilter && (
        <div className="top-filter" style={{ marginLeft: 0, marginRight: 0 }}>
          <div className="flex-between">
            <SearchInput
              placeholder={props.placeholder}
              handleSearch={(val) => {
                handleSearch && handleSearch(val);
              }}
            />
            <div className="d-flex" >
              {props?.headerButtonProps && props.isHeaderButton ? (
                <button
                  className="addButton"
                  onClick={() => handleButtonFunc(props?.headerButtonProps?.route)}
                >
                  {props?.headerButtonProps?.buttonName}
                </button>
              ) : (
                ""
              )}
              {!hideExportButton && <button className="exportButton ml1" onClick={exportToExcel}>
                Export to Excel
              </button>}
              <ShowMoreLess topFilters={props?.topFilters} />

            </div>
          </div>
        </div>
      )}
      {props?.addBtn ? (
        <div className="top-filter" style={{ marginLeft: 0, marginRight: 0 }}>
          <div className="flex-between">
            <SearchInput
              placeholder={props.placeholder}
              handleSearch={(val) => {
                handleSearch && handleSearch(val);
              }}
            />
            <button
              className="addButton"
              onClick={() => props.handleButtonFunc()}
            >
              {props.buttonText}
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      <Table columns={columns} dataSource={data} pagination={pagination} scroll={scroll} />
    </div>
  );
};

export default withRouter(TableWrap);
