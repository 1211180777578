import React from "react";
import {
  Input,
  Form,
  Button,
  Typography,
  Divider,
  Upload,
  message,
  Row,
  Col,
  Switch,
} from "antd";
import { withRouter } from "react-router";
import Text from "antd/lib/typography/Text";
import { InboxOutlined } from "@ant-design/icons";
import "../clients.css";
const { Dragger } = Upload;

const { TextArea } = Input;

const { Title } = Typography

const certificateOfIncorporationFileUploadProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const cancelledChequeFileUploadProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const otherDocumentsFileUploadProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const gstCertificateFileUploadProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const uploadImageProps = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};

const OrganisationProfile = (props) => {
  const handleCancel = () => {
    props.history.push("/admin/clients/");
  };

  function onChange(checked) {
    console.log(`switch to ${checked}`);
  }

  return (
    <>
      <div className="contentWrapper" style={{ height: "100%", flex: 1 }}>
        <Form
          layout="vertical"
          // onFinish={handleSubmit}
          className="settings_form"
        >
          <div className="d-flex flex-between">
            <Title level={5} className="cardTitle">
              Organisation Profile
            </Title>
            <div className="d-flex">
              <Title level={5} className="cardTitle">
                Status
              </Title>
              &nbsp;&nbsp;
              <Switch defaultChecked onChange={onChange} />
            </div>
          </div>
          <Divider />
          <Row>
            <Col span={12}>
              <Form.Item label="Company Name" name="name">
                <Input placeholder="Company Name" className="inputTag" />
              </Form.Item>
              <Form.Item label="Company Registration No" name="parent_name">
                <Input
                  placeholder="Company Registration No"
                  className="inputTag"
                />
              </Form.Item>
              <Form.Item label="Address" name="description">
                <TextArea
                  // value={value}
                  // onChange={this.onChange}
                  placeholder="Enter Address"
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  className="inputTag"
                />
              </Form.Item>
            </Col>
            <Col span={12} className="uploadSection align-items-center">
              <Text>Upload your company logo</Text>
              <Dragger {...uploadImageProps}>
                <p className="ant-upload-text">
                  <InboxOutlined />
                  Drag here to upload JPG/PNG
                  <br />
                  Or click here to attach
                </p>
              </Dragger>
              <p className="hint">
                Preferred Image Size: 240px x 240px @ 72 DPI Maximum size of
                1MB.
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item label="City" name="parent_name">
                <Input placeholder="City" className="inputTag" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="State" name="parent_name">
                <Input placeholder="State" className="inputTag" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Country" name="parent_name">
                <Input placeholder="Country" className="inputTag" />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Pincode" name="parent_name">
                <Input placeholder="Pincode" className="inputTag" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item label="Office Number" name="parent_name">
                <Input placeholder="Office Number" className="inputTag" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="GSTIN No" name="parent_name">
                <Input placeholder="GSTIN No" className="inputTag" />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item label="TAN No" name="parent_name">
                <Input placeholder="TAN No" className="inputTag" />
              </Form.Item>
            </Col>
          </Row>

          <Title level={5} className="cardTitle">
            KYC Documents
          </Title>
          <Divider />
          <Row style={{ lineHeight: 3 }}>
            <Col span={12} className="uploadSection m2" style={{ maxWidth: 'none !important' }}>
              <Text className="align-items-center d-flex flex-center">Certificate of Incorporation</Text>
              <Dragger {...certificateOfIncorporationFileUploadProps}>
                <p className="ant-upload-text">
                  <InboxOutlined />
                  Drag here to upload JPG/PNG
                  <br />
                  Or click here to attach
                </p>
              </Dragger>
            </Col>
            <Col span={12} className="uploadSection m2" style={{ maxWidth: 'none !important' }}>
              <Text className="align-items-center d-flex flex-center">Cancelled Cheque</Text>
              <Dragger {...cancelledChequeFileUploadProps}>
                <p className="ant-upload-text">
                  <InboxOutlined />
                  Drag here to upload JPG/PNG
                  <br />
                  Or click here to attach
                </p>
              </Dragger>
            </Col>
            <Col span={12} className="uploadSection m2" style={{ maxWidth: 'none !important' }}>
              <Text className="align-items-center d-flex flex-center">Other Documents</Text>
              <Dragger {...otherDocumentsFileUploadProps}>
                <p className="ant-upload-text">
                  <InboxOutlined />
                  Drag here to upload JPG/PNG
                  <br />
                  Or click here to attach
                </p>
              </Dragger>
            </Col>
            <Col span={12} className="uploadSection m2" style={{ maxWidth: 'none !important' }}>
              <Text className="align-items-center d-flex flex-center">GST Certificate</Text>
              <Dragger {...gstCertificateFileUploadProps}>
                <p className="ant-upload-text">
                  <InboxOutlined />
                  Drag here to upload JPG/PNG
                  <br />
                  Or click here to attach
                </p>
              </Dragger>
            </Col>
            <Col span={12} className="uploadSection m2" style={{ maxWidth: 'none !important' }}>
              <Text className="align-items-center d-flex flex-center">Address Proof</Text>
              <Dragger {...gstCertificateFileUploadProps}>
                <p className="ant-upload-text">
                  <InboxOutlined />
                  Drag here to upload JPG/PNG
                  <br />
                  Or click here to attach
                </p>
              </Dragger>
            </Col>
          </Row>
          
          <div className="d-flex flex-end">
            <Button
              key="2"
              className="cancelButton ml1 mt1 w10"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <Button
              key="1"
              htmlType="submit"
              className="formButton mt1 ml2 w10"
            >
              Next
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

export default withRouter(OrganisationProfile);
