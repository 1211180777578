
import {
  Button,
  Input, message
} from 'antd';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { CreateCardVendors, create_card_vendors, get_card_vendors } from '../../../actions/cards';
const AddCardVendor = ({ onCancel }) => {
  const dispatch = useDispatch();
  const add_cv = useSelector(state => state?.cards?.create_card_vendors?.data)
  const add_cv_loading = useSelector(state => state?.cards?.create_card_vendors?.loading)
  const formik = useFormik({
    initialValues: {
      name: "",
      outletId: "",
      branchId: "",
      tenantId: "",
      ocp_apim_subscription_key: "",
      base_url: "",
      channelId: ""
    },
    validationSchema: yup.object().shape({
      name: yup.string().required(),
      outletId: yup.string().required(),
      branchId: yup.string().required(),
      tenantId: yup.string().required(),
      ocp_apim_subscription_key: yup.string().required(),
      base_url: yup.string().required(),
      channelId: yup.string().required()
    }),
    onSubmit: (vals) => {
      dispatch(create_card_vendors(vals))
    }
  })


  useEffect(() => {
    if (add_cv?.status) {
      message.success("Added user");
      dispatch({
        type: CreateCardVendors.RESET
      })
      dispatch(get_card_vendors())
      onCancel()
      formik.resetForm()
      formik.setFieldValue("permission_ids", [])
    }

    if (add_cv?.status === false) {
      message.error("Failed to add user");
      dispatch({
        type: CreateCardVendors.RESET
      })
    }
    // eslint-disable-next-line
  }, [add_cv])
  return (
    <>
      <form className="modal-form staff" onSubmit={formik.handleSubmit}>
        <div className='input-div'>
          <label>Name</label>
          <Input autoComplete='off' placeholder="Enter Name" value={formik.values.name} onChange={formik.handleChange} name="name" />
          {formik.touched.name && formik.errors.name && <div className='error'>{formik.errors.name}</div>}

        </div>
        <div className='input-div'>
          <label>Outlet Id</label>
          <Input autoComplete='off' placeholder="Enter Outlet Id" value={formik.values.outletId} onChange={formik.handleChange} name="outletId" />
          {formik.touched.outletId && formik.errors.outletId && <div className='error'>{formik.errors.outletId}</div>}

        </div>
        <div className='input-div'>
          <label>Branch Id</label>
          <Input autoComplete='off' placeholder="Enter Branch Id" value={formik.values.branchId} onChange={formik.handleChange} name="branchId" />
          {formik.touched.branchId && formik.errors.branchId && <div className='error'>{formik.errors.branchId}</div>}

        </div>
        <div className='input-div'>
          <label>Tenant Id</label>
          <Input autoComplete='off' placeholder="Enter Tenant Id" value={formik.values.tenantId} onChange={formik.handleChange} name="tenantId" />
          {formik.touched.tenantId && formik.errors.tenantId && <div className='error'>{formik.errors.tenantId}</div>}

        </div>
        <div className='input-div'>
          <label>Channel Id</label>
          <Input autoComplete='off' placeholder="Enter Channel Id" value={formik.values.channelId} onChange={formik.handleChange} name="channelId" />
          {formik.touched.channelId && formik.errors.channelId && <div className='error'>{formik.errors.channelId}</div>}

        </div>
        <div className='input-div'>
          <label>Base URL</label>
          <Input autoComplete='off' placeholder="Enter Base Url" value={formik.values.base_url} onChange={formik.handleChange} name="base_url" />
          {formik.touched.base_url && formik.errors.base_url && <div className='error'>{formik.errors.base_url}</div>}

        </div>
        <div className='input-div'>
          <label>OCR Subscription Key</label>
          <Input autoComplete='off' placeholder="Enter OCR Subscription Key" value={formik.values.ocp_apim_subscription_key} onChange={formik.handleChange} name="ocp_apim_subscription_key" />
          {formik.touched.ocp_apim_subscription_key && formik.errors.ocp_apim_subscription_key && <div className='error'>{formik.errors.ocp_apim_subscription_key}</div>}

        </div>
        <div className='d-flex'>
          <Button key="1" htmlType="submit" className="formButton mt1" disabled={add_cv_loading} loading={add_cv_loading}>Save</Button>
          <Button key="2" className="cancelButton ml1 mt1" onClick={() => {
            onCancel()
            formik.resetForm()
          }}>Cancel</Button>
        </div>
      </form>
    </>
  );
}

export default AddCardVendor

