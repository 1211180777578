import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GLOBAL_STATIC_FILES } from "../../utils";
import { Logout, logout } from "../../actions/auth";
import "./header.css";
import { useHistory } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const log_out = useSelector(state => state?.auth?.logout)
  const history = useHistory()
  useEffect(() => {
    if (log_out?.success) {
      dispatch({
        type: Logout.RESET
      })
      localStorage.clear();
      history.push("/admin/login");
    }
    // eslint-disable-next-line
  }, [log_out])
  return (
    <div className="header-div">
      <div className="flex-between w100 pl2 pr2">
        <div className="w50">
          <img
            src={GLOBAL_STATIC_FILES.COMPANY_LOGO}
            alt={"logo"}
            className="header-logo mbHalf"
          />
        </div>
        <div className="flex-end w50">
          <h2
            style={{
              marginBottom: 0,
              color: "#fff",
            }}
          >
            Paycraft Admin
          </h2>
          <button className="ml1 logout" onClick={() => {
            dispatch(logout())
          }}>Logout</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
