import { Menu } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import DashboardIcon from "../../assets/icons/dashboard_icon.svg";
import ReportIcon from "../../assets/icons/report_icon.svg";
import { hasAccess, RouteConstants } from "../../utils";
import "./leftNavigation.css";

class LeftNavigation extends React.Component {
  state = {
    collapsed: false,
    selectedKey:"1  "
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <div style={{ width: 200 }}>
        <Menu
          mode="inline"
          inlineCollapsed={this.state.collapsed}
          className="navigation-div"
          style={{
            paddingTop: "0.8rem"
          }}
          defaultSelectedKeys={[this.state.selectedKey]}
          onClick={(info)=>{
            this.setState({
              selectedKey: info.key
            })
          }}
        >
          {(hasAccess("clients", "write") || hasAccess("clients", "read")) && <Menu.Item
            key="1"
            icon={
              <img src={DashboardIcon} alt={"logo"} className="menu-logo" />
            }
          >
            <Link to={RouteConstants.CLIENTS}>Clients</Link>
          </Menu.Item>}
          {(hasAccess("permission_users", "write") || hasAccess("permission_users", "read")) && <Menu.Item
            key="2"
            icon={
              <img src={DashboardIcon} alt={"logo"} className="menu-logo" />
            }
          >
            <Link to={RouteConstants.USERS}>Users</Link>
          </Menu.Item>}
          {(hasAccess("cards", "write") || hasAccess("cards", "read")) && <Menu.Item
            key="3"
            icon={<img src={ReportIcon} alt={"logo"} className="menu-logo" />}
          >
            <Link to={RouteConstants.CARDS}> Cards</Link>
          </Menu.Item>}
          {(hasAccess("card_imports", "write") || hasAccess("card_imports", "read")) &&
            <Menu.Item
              key="4"
              icon={<img src={ReportIcon} alt={"logo"} className="menu-logo" />}
            >
              <Link to={RouteConstants.CARD_IMPORTS}> Card Imports</Link>
            </Menu.Item>}
          {((hasAccess("card_vendors", "write") || hasAccess("card_vendors", "read"))) &&
            <Menu.Item
              key="5"
              icon={<img src={ReportIcon} alt={"logo"} className="menu-logo" />}
            >
              <Link to={RouteConstants.CARD_VENDORS}>Card Vendors</Link>
            </Menu.Item>}
          {(hasAccess("staff_users", "write") || hasAccess("staff_users", "read")) &&
            <Menu.Item
              key="6"
              icon={<img src={ReportIcon} alt={"logo"} className="menu-logo" />}
            >
              <Link to={RouteConstants.STAFF_USERS}>Staff Users</Link>
            </Menu.Item>}
          {(hasAccess("leads", "write") || hasAccess("leads", "read")) &&
            <Menu.Item
              key="7"
              icon={<img src={ReportIcon} alt={"logo"} className="menu-logo" />}
            >
              <Link to={RouteConstants.LEADS}>Leads</Link>
            </Menu.Item>}
          {/* <Menu.Item
            key="4"
            icon={<img src={TripsIcon} alt={"logo"} className="menu-logo" />}
          >
            KYC Management
          </Menu.Item>
          <Menu.Item
            key="5"
            icon={<img src={AdvancesIcon} alt={"logo"} className="menu-logo" />}
          >
            Orders
          </Menu.Item>
          <Menu.Item
            key="12"
            icon={<img src={SettingIcon} alt={"logo"} className="menu-logo" />}
          >
            Permissions
          </Menu.Item> */}
        </Menu>
      </div>
    );
  }
}

export default LeftNavigation;
