import _ from "lodash";
import { call, put } from "redux-saga/effects";
import { isEqual, find, remove, findIndex } from "lodash";
import endpoint from "./config/Axios";
import Logo from "./assets/images/logo-1-01.png";

export const RouteConstants = {
  ROOT: "/",
  CLIENTS: "/admin/clients/",
  USERS: "/admin/users/",
  REPORTS: "/admin/reports/",
  TRIPS: "/admin/trips/",
  ADVANCES: "/admin/advances/",
  CARDS: "/admin/cards/",
  CARD_IMPORTS: "/admin/cards/imports",
  STAFF_USERS: "/admin/staff-users",
  CARD_VENDORS: "/admin/card-vendors",
  LEADS: "/admin/leads",
  TRANSFERS: "/admin/transfers/",
  ACCOUNT: "/admin/account/",
  ANALYTICS: "/admin/analytics/",
  SETTINGS: "/admin/setting/",
  RESETPASSWORD: "/admin/resetpassword/",
  SETTING_ORGANISATION: "/admin/setting/organisation/",
  SETTING_USERS: "/admin/setting/users/",
  SETTING_USERS_ADD_USER: "/admin/setting/users/add_user/",
  SETTING_ROLES: "/admin/setting/roles/",
  SETTING_GRADES: "/admin/setting/grades/",
  SETTING_CUSTOM_FIELD: "/admin/setting/custom_field/",
  SETTING_CATEGORIES: "/admin/setting/categories/",
  SETTING_POLICIES: "/admin/setting/policies/",
  SETTING_POLICIES_EXPENSE: "/admin/setting/policies/expense/",
  SETTING_POLICIES_CARDS: "/admin/setting/policies/cards/",
  SETTING_APPROVALS: "/admin/setting/approvals/",
  SETTING_CUSTOMISATION: "/admin/setting/customisation/",
  SETTING_CUSTOMISATION_EXPENSES: "/admin/setting/customisation/expenses/",
  SETTING_CUSTOMISATION_REPORTS: "/admin/setting/customisation/reports/",
};

export const GLOBAL_STATIC_FILES = {
  COMPANY_LOGO: Logo,
};

export const ActionTypesFactory = (prefix, type) => {
  let p = _.upperCase(prefix) + "/" + _.upperCase(type);
  return {
    REQUEST: p + "_REQUEST",
    INPROGRESS: p + "_INPROGRESS",
    SUCCESS: p + "_SUCCESS",
    FAILED: p + "_FAILED",
    CHANGED: p + "_CHANGED",
    RESET: p + "_RESET",
    custom: (name) => {
      return `${p}_${name}`;
    },
  };
};

export const sagaFunctions = (action_type, method, api, data, headers) => {
  return function* () {
    let res = null;
    try {
      if (method === "post" || method === "put") {
        res = yield call(endpoint[method], api, data, {
          headers,
        });
      } else {
        res = yield call(endpoint[method], api, {
          headers,
          params: data,
        });
      }
      if (res.status < 400 && res?.data?.status) {
        yield put({
          type: action_type.SUCCESS,
          payload: res.data,
        });
      } else {
        yield put({
          type: action_type.FAILED,
          payload: res.data,
        });
      }
    } catch (err) {
      yield put({
        type: action_type.FAILED,
        payload: err?.response?.data,
      });
    }
  };
};

export const ReducerFunctions = (ActionType, initialState) => {
  return function (state = initialState, action) {
    let data = Array.isArray(action?.payload)
      ? [...action?.payload]
      : { ...action?.payload };
    switch (action?.type) {
      case ActionType.REQUEST:
        return {
          ...state,
          loading: true,
        };
      case ActionType.SUCCESS: {
        return {
          ...state,
          data,
          loading: false,
          success: true,
        };
      }
      case ActionType.FAILED:
        return {
          ...state,
          data,
          loading: false,
          success: false,
        };
      case ActionType.RESET:
        return {
          loading: false,
        };

      default:
        return state;
    }
  };
};

export const objectToFormData = (obj, form, namespace) => {
  const fd = form || new FormData();
  let formKey;
  for (const property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = `${namespace}[${property}]`;
      } else {
        formKey = property;
      }
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        fd.append(formKey, obj[property]);
      }
    }
  }
  return fd;
};

export const isEqualLodash = (value, other) => isEqual(value, other);
export const findLodash = (array, object) => find(array, object);
export const removeLodash = (array, object) => remove(array, object);
export const findIndexLodash = (array, object) => findIndex(array, object);
export const isEqualObject = (fromPayload, toPayload) =>
  JSON.stringify(fromPayload) === JSON.stringify(toPayload);

export const hasAccess = (key, role) => {
  let permissions = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : ""
  if (permissions?.length) {
    if (key && role) {
      console.log(permissions?.find(ele => ele.key?.includes(key + "_" + role)));
      return permissions?.find(ele => ele.key?.includes(key + "_" + role))
    }
  }

  return false
}