import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "../Common/Table/Table";

import { getLeads } from "../../actions/clients/clients";
import PageHeader from "../PageHeader";

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',

  },
  {
    title: 'Phone',
    dataIndex: 'phone_no',
    key: 'phone_no',

  },
  {
    title: 'Company Name',
    dataIndex: 'company_name',
    key: 'company_name',

  },
  {
    title: 'Work email',
    dataIndex: 'work_email',
    key: 'work_email',
  },
  {
    title: 'Description',
    dataIndex: 'message',
    key: 'message',
  },
];


const Leads = () => {
  const dispatch = useDispatch();
  const data = useSelector(state => state?.auth?.leads?.data?.response?.list);
  useEffect(() => {
    dispatch(getLeads({
      page: 1
    }))
    // eslint-disable-next-line
  }, [])

  return (
    <><div className="heading-div">
      <PageHeader
        title={`Leads`} />
    </div>
      <Table
        columns={columns}
        data={data}
      /></>
  );
};

export default Leads;

