// import { Menu } from "antd";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import { get_cards } from "../../../actions/cards/cards";
import { getCompanyList } from "../../../actions/clients/clients";
// import PageHeader from "../../PageHeader";
import Table from "../../Common/Table/Table";
import endpoint from "../../../config/Axios";
import { apis } from "../../../config/APIs";
import { saveAs } from "file-saver";
import json2csv from 'json2csv';
// import moment from "moment";

const CardDetailsList = () => {
  const [filters, setFilters] = React.useState({});
  const client_data = useSelector(
    (state) =>
      state?.client?.getClientList?.data?.response?.companies?.map(ele => ele?.name)
  );
  const cards = useSelector(
    (state) => state?.cards?.get_cards?.data?.response?.cards
  );
  const cards_total = useSelector(
    (state) => state?.cards?.get_cards?.data?.total_records
  );
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(get_cards({}));
    dispatch(getCompanyList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const topFilters = useMemo(() => [
    {
      label: "Company",
      actionSearch: (value) => {
        dispatch(getCompanyList({ search: value }));
      },
      action: (value) => {
        dispatch(
          get_cards({
            ...filters,
            page: filters.page ? filters.page : 1,
            company: value,
          })
        );
        setFilters({
          ...filters,
          company: value,
        });
      },
      type: "dropdown",
      options: client_data ? client_data : [],
      searchOption: true,
      // eslint-disable-next-line
    }], [client_data, filters])
  const handleSearch = (value) => {
    dispatch(
      get_cards({ page: filters.page ? filters.page : 1, search: value })
    );
    setFilters({
      ...filters,
      search: value,
    });
  };

  const columns = [
    {
      title: "S.NO",
      dataIndex: "id",
      key: "id",
      render: (text, data, i) => {
        return <div className={`status-line`}>{i + 1}</div>;
      },
    },
    {
      title: "Card Ref No",
      dataIndex: "card_reference_no",
      key: "card_reference_no",
      className: "user-col",
    },
    {
      title: "Card number",
      dataIndex: "card_number",
      key: "card_number",
      width: 150,
      render: (text) => (
        <div>
          {text?.includes("XXX") ? text?.replace(/\w{4}(?=.)/g, '$& ')?.replaceAll("X", "*")?.slice(
            text?.length - 7
          ) : text?.slice(
            text?.length - 9
          )}
        </div>
      ),
    },
    {
      title: "Company",
      dataIndex: "company_name",
      key: "company_name",
      className: "user-col",
    },
    {
      title: "Employee Name",
      dataIndex: "employee_details",
      key: "employee_details",
      render: (text) => {
        return <div>{text?.name ?? "-"}</div>;
      },
    },
    {
      title: "Employee Mobile",
      dataIndex: "employee_details",
      key: "employee_details",
      render: (text) => {
        return <div>{text?.mobile}</div>;
      },
    },
    {
      title: "Employee Email",
      dataIndex: "employee_details",
      key: "employee_details",
      render: (text) => {
        return <div>{text?.email}</div>;
      },
    },
    {
      title: "Card Status",
      dataIndex: "status",
      className: "user-col",
      key: "status",
    },
    {
      title: "KYC Status",
      dataIndex: "kyc_flag",
      className: "user-col",
      key: "kyc_flag",
    },
    {
      title: "Available Balance",
      dataIndex: "balance",
      className: "user-col",
      key: "balance",
      render: (text, data) => {
        return <span>{text}</span>;
      },
    },
    {
      title: "Expense Wallet",
      dataIndex: "expense_wallet",
      className: "user-col",
      key: "expense_wallet",
      render: (text, data) => {
        return (
          <span>
            {
              data?.wallets?.find(
                (ele) => ele["wallet_name"] === "EXPENSE"
              )?.balance
            }
          </span>
        );
      },
    },
    {
      title: "Meal Wallet",
      dataIndex: "benefits_wallet",
      className: "user-col",
      key: "benefits_wallet",
      render: (text, data) => {
        return (
          <span>
            {
              data?.wallets?.find(
                (ele) => ele["wallet_name"] === "MEAL"
              )?.balance
            }
          </span>
        );
      },
    },
    {
      title: "Reimbursement Wallet",
      dataIndex: "reimbursement_wallet",
      className: "user-col-lg",
      key: "reimbursement_wallet",
      render: (text, data) => {
        return (
          <span>
            {
              data?.wallets?.find(
                (ele) => ele["wallet_name"] === "REIMBURSEMENT" || ele["wallet_name"] === "REIMBURSE"
              )?.balance
            }
          </span>
        );
      },
    },
    {
      title: "Fuel Wallet",
      dataIndex: "fuel_wallet",
      className: "user-col",
      key: "fuel_wallet",
      render: (text, data) => {
        return (
          <span>
            {
              data?.wallets?.find(
                (ele) => ele["wallet_name"] === "FUEL"
              )?.balance
            }
          </span>
        );
      },
    },
    {
      title: "Gift Wallet",
      dataIndex: "gift_wallet",
      className: "user-col",
      key: "gift_wallet",
      render: (text, data) => {
        return (
          <span>
            {
              data?.wallets?.find(
                (ele) => ele["wallet_name"] === "GIFT"
              )?.balance
            }
          </span>
        );
      },
    },
    // {
    //   title: "Actions",
    //   dataIndex: "actions",
    //   fixed: "right",
    //   width: 100,
    //   key: "actions",
    //   render: () => (
    //     <div className="actionsCard">
    //       <Switch defaultChecked onChange={onChange} />

    //       <Link className="editButtonDiv" to={"#"} style={{ color: "#454545" }}>
    //         <EyeFilled />
    //         &nbsp;&nbsp;View
    //       </Link>
    //       <Dropdown overlay={menu} placement="bottomRight" arrow>
    //         <div className="expandCardIconDiv">
    //           <DownSquareOutlined />
    //         </div>
    //       </Dropdown>
    //     </div>
    //   ),
    // },
  ];

  return (
    <Table
      columns={columns}
      data={cards}
      topFilters={topFilters}
      handleSearch={handleSearch}
      isTopFilter={true}
      scroll={{ x: 2500 }}
      pagination={{
        total: cards_total || 0,
        onChange: (page) => {
          dispatch(get_cards({ ...filters, page }));
        },
      }}
      exportToExcel={() => {
        endpoint.get(apis.staffCards, {
          params: {
            csv: true
          }
        }).then(res => {
          let usrs = res?.data?.response?.cards
          let csv = json2csv.parse(usrs?.map(ele => {
            delete ele.id
            delete ele.wallet
            return ele
          }));
          var blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
          saveAs(blob, "cards.csv");
        })

      }}
    />
  );
};

export default withRouter(CardDetailsList);
