import { InboxOutlined } from "@ant-design/icons";
import { message } from "antd";
import { useDropzone } from "react-dropzone";

const Dropzone = ({ file, setFile, logo, acceptedFileTypes }) => {
  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles?.length) {
      message.error("Only PNG/JPG are allowed.")
    } else
      setFile(acceptedFiles?.[0]);
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: acceptedFileTypes,
    onDrop,
  });

  return (
    <div className="ant-upload" {...getRootProps()}>
      <input {...getInputProps()} />
      {typeof logo === "string" && <img src={logo} alt="" style={{
        width: "15rem"
      }} />}
      {file ? (
        <p className="ant-upload-text">{file?.name}</p>
      ) : isDragActive ? (
        <p>Drop the files here ...</p>
      ) : (
        <p className="ant-upload-text flex-center flex-col text-center">
          <div>
            <InboxOutlined />
          </div>
          Drag here to upload JPG/PNG
          <br />
          Or click here to attach
        </p>
      )}
    </div>
  );
};

export default Dropzone;
