import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions } from "../../utils";
import { CardImports, Cards, CreateCardVendors, DeleteCardVendor, GetCardVendors, LinkCardVendor, UnlinkCardVendor } from "./cards";

function get_card_vendors(req) {
  return sagaFunctions(GetCardVendors, "get", apis.card_vendors, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}

function create_card_vendors(req) {
  return sagaFunctions(CreateCardVendors, "post", apis.card_vendors, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}

function delete_card_vendors(req) {
  return sagaFunctions(DeleteCardVendor, "post", apis.delete_vendor, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}

function link_card_vendors(req) {
  return sagaFunctions(LinkCardVendor, "get", apis.link_card_vendor, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}

function unlink_card_vendors(req) {
  return sagaFunctions(UnlinkCardVendor, "get", apis.unlink_card_vendor, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}


function get_cards(req) {
  return sagaFunctions(Cards, "get", apis.staffCards, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}

function get_card_imports(req) {
  return sagaFunctions(CardImports, "get", apis.staffCardImports, req.payload, {
    Authorization: localStorage.getItem("token"),
  })();
}

export function* cardsWatcher() {
  yield takeLatest(Cards.REQUEST, get_cards);
  yield takeLatest(CardImports.REQUEST, get_card_imports);
  yield takeLatest(GetCardVendors.REQUEST, get_card_vendors);
  yield takeLatest(CreateCardVendors.REQUEST, create_card_vendors);
  yield takeLatest(UnlinkCardVendor.REQUEST, unlink_card_vendors);
  yield takeLatest(LinkCardVendor.REQUEST, link_card_vendors);
  yield takeLatest(DeleteCardVendor.REQUEST, delete_card_vendors);
}
