import { takeLatest } from "redux-saga/effects";
import { apis } from "../../config/APIs";
import { sagaFunctions } from "../../utils";
import {
  AddCompany,
  AddStaffCompany,
  BaseCurrencies,
  CompanyEnable,
  CompanyList,
  Leads,
  StaffCompanyDetails,
  StaffCompanyList,
} from "./clients";

function addCompany(req) {
  let formdata = new FormData();
  Object.keys(req.payload).forEach((ele) => {
    formdata.append(ele, req.payload[ele]);
  });
  return sagaFunctions(AddCompany, "post", apis.companies, formdata, {
    "Content-Type": "multipart/formdata",
    Authorization: localStorage.getItem("token"),
  })();
}

function getCompanyList(req) {
  return sagaFunctions(CompanyList, "get", apis.clientList, req.payload)();
}

function getLeads(req) {
  return sagaFunctions(Leads, "get", apis.leads, req.payload)();
}

function enalbeCompany(req) {
  return sagaFunctions(
    CompanyEnable,
    "post",
    apis.staffEnableCompany +
    `?company_id=${req.payload.cmpId}&enabled=${req.payload.enabled}`
  )();
}

function getStaffCompany(req) {
  return sagaFunctions(StaffCompanyDetails, "get", apis.staffCompanyDetails, {
    id: req.payload,
  })();
}

function addStaffCompany(req) {
  let formdata = new FormData();
  Object.keys(req.payload).forEach((ele) => {
    formdata.append(ele, req.payload[ele]);
  });
  return sagaFunctions(AddStaffCompany, "post", req.payload.id ? apis.staffCompanies + "/update" : apis.staffCompanies, formdata, {
    "Content-Type": "multipart/formdata",
    Authorization: localStorage.getItem("token"),
  })();
}

function getStaffCompanyList(req) {
  return sagaFunctions(
    StaffCompanyList,
    "get",
    apis.staffCompanies,
    req.payload
  )();
}

function getBaseCurrencies(req) {
  return sagaFunctions(
    BaseCurrencies,
    "get",
    apis.base_currencies,
    req.payload
  )();
}

export function* companyWatcher() {
  yield takeLatest(BaseCurrencies.REQUEST, getBaseCurrencies);
  yield takeLatest(CompanyList.REQUEST, getCompanyList);
  yield takeLatest(Leads.REQUEST, getLeads);
  yield takeLatest(AddCompany.REQUEST, addCompany);
  yield takeLatest(AddStaffCompany.REQUEST, addStaffCompany);
  yield takeLatest(StaffCompanyList.REQUEST, getStaffCompanyList);
  yield takeLatest(StaffCompanyDetails.REQUEST, getStaffCompany);
  yield takeLatest(CompanyEnable.REQUEST, enalbeCompany);
}
