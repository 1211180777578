import { CloseCircleOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Route, withRouter } from "react-router-dom";

import ApprovalsIcon from "../../../assets/icons/Policies_Limits@3x.png";
import PageHeader from "../../PageHeader";
import "../clients.css";
import ContactDetails from "./ContactDetails";
// import KycDocuments from "./KycDocuments";
import AddNewClientOrganisationProfile from "./OrganisationProfile";
import ProductConfiguration from "./ProductConfiguration";
import RegionalSettings from "./RegionalSettings";

const leftSideButtons = [
  {
    buttonName: "Organisation Profile",
    icon: ApprovalsIcon,
    path: "/organisation-profile",
  },
  // {
  //   buttonName: "KYC Documents",
  //   icon: ApprovalsIcon,
  //   path: "/kyc-documents",
  // },
  // {
  //   buttonName: "Contact Details",
  //   icon: ApprovalsIcon,
  //   path: "/contact-details",
  // },
  // {
  //   buttonName: "Regional Settings",
  //   icon: ApprovalsIcon,
  //   path: "/regional-settings",
  // },
  // {
  //   buttonName: "Product Configuration",
  //   icon: ApprovalsIcon,
  //   path: "/product-configuration",
  // },
  // {
  //   buttonName: "Subscription & Pricing",
  //   icon: ApprovalsIcon,
  //   path: "/subscription-pricing",
  // },
  // {
  //   buttonName: "Additional Settings",
  //   icon: ApprovalsIcon,
  //   path: "/additional-settings",
  // }
];

const AddNewClientDashboard = (props) => {
  const [updatePageIndex, setUpdatePageIndex] = useState(0);
  const history = props.history;

  const navigateToPage = (index, route) => {
    setUpdatePageIndex(index);
    history.push(`/admin/clients/add-new-client${route}`);
  };

  return (
    <>
      <div className="heading-div d-flex flex-between" style={{ height: '100%' }}>
        <PageHeader
          title={`Add New Client`}
        />
        <CloseCircleOutlined
          style={{ fontSize: 20 }}
          onClick={() => history.push("/admin/clients/")}
        />
      </div>
      <div
        className="d-flex flex-start"
        style={{ alignItems: "flex-start", height: "95%" }}
      >
        <div className="addNewClientLeftSideCard">
          {leftSideButtons.map((leftSideButton, index) => (
            <div
              className={
                updatePageIndex === index
                  ? "activeLeftSideButton d-flex flex-between w100 p1"
                  : "inActiveLeftSideButton d-flex flex-between w100 p1"
              }
            >
              <button
                key={index}
                onClick={() => navigateToPage(index, leftSideButton.path)}
              >
                <img
                  className={
                    updatePageIndex === index
                      ? "activeLeftSideIconTag mr1"
                      : "inActiveLeftSideIconTag mr1"
                  }
                  src={leftSideButton.icon}
                  alt="leftside-icon"
                />
                {leftSideButton.buttonName}
              </button>
              {/* <span className="leftSideButtonCircle ml1"></span> */}
            </div>
          ))}
        </div>
        <Route
          exact
          path={props.path + "/organisation-profile"}
          component={AddNewClientOrganisationProfile}
        />
        {/* <Route
          exact
          path={props.path + "/kyc-documents"}
          component={KycDocuments}
        /> */}
        <Route
          exact
          path={props.path + "/contact-details"}
          component={ContactDetails}
        />
        <Route
          exact
          path={props.path + "/regional-settings"}
          component={RegionalSettings}
        />
        <Route
          exact
          path={props.path + "/product-configuration"}
          component={ProductConfiguration}
        />
      </div>
    </>
  );
};

export default withRouter(AddNewClientDashboard);
